import React from 'react'
import {Failure} from 'ekyc-web-module'
const failure = () => {
  return (
    <div>Inside ekyc failure
      <Failure/>
    </div>
  )
}

export default failure